import axios from "axios";
import api from "../api/index";

// const START_RETURN_REQUEST = "TYP/START_RETURN_REQUEST";
// const START_RETURN_SUCCESS = "TYP/START_RETURN_SUCCESS";
// const START_RETURN_FAILURE = "TYP/START_RETURN_FAILURE";

const GET_PARCELS_REQUEST = "TYP/GET_PARCELS_REQUEST";
const GET_PARCELS_SUCCESS = "TYP/GET_PARCELS_SUCCESS";
const GET_PARCELS_FAILURE = "TYP/GET_PARCELS_FAILURE";

const ADD_RETURN_REQUEST = "TYP/ADD_RETURN_REQUEST";
const ADD_RETURN_SUCCESS = "TYP/ADD_RETURN_SUCCESS";
const ADD_RETURN_FAILURE = "TYP/ADD_RETURN_FAILURE";

const initialState = {
  loading: false,
  error: false,
  data: "",
  parcels: [],
};

export default function reducerSlots(state = initialState, action) {
  switch (action.type) {
    // case START_RETURN_REQUEST:
    //   return { ...state, loading: true, error: false };
    // case START_RETURN_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     data: action.payload,
    //   };
    // case START_RETURN_FAILURE:
    //   return { ...state, loading: false, data: [], error: action.payload };
    case ADD_RETURN_REQUEST:
      return { ...state, loading: true, error: false };
    case ADD_RETURN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case ADD_RETURN_FAILURE:
      return { ...state, loading: false, data: "", error: action.payload };
    case GET_PARCELS_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_PARCELS_SUCCESS:
      return {
        ...state,
        loading: false,
        parcels: action.payload,
      };
    case GET_PARCELS_FAILURE:
      return { ...state, loading: false, parcels: "", error: action.payload };

    default:
      return state;
  }
}

// export const startReturn = (id, params) => {
//   return async (dispatch) => {
//     dispatch({
//       type: START_RETURN_REQUEST,
//     });
//     try {
//       console.log("params", params);
//       const { url, method, headers, data } = api.startReturn(id, params);
//       const link = await axios({
//         url,
//         method,
//         headers,
//         data,
//       });

//       dispatch({
//         type: START_RETURN_SUCCESS,
//         payload: "success",
//       });
//     } catch (error) {
//       console.log("error", error);
//       console.log("error", error.response);
//       dispatch({
//         type: START_RETURN_FAILURE,
//         payload: error,
//       });
//     }
//   };
// };

export const addReturn = (data) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_RETURN_REQUEST,
    });
    try {
      const { url, method, headers } = api.addReturn(data);

      const returnRequest = await axios({
        url,
        method,
        headers,
        data,
      });
      dispatch({
        type: ADD_RETURN_SUCCESS,
        payload: "success",
      });
      return "success";
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);
      dispatch({
        type: ADD_RETURN_FAILURE,
        payload: error,
      });
    }
  };
};

export const getParcels = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_PARCELS_REQUEST,
    });
    try {
      const { url, method, headers } = api.getParcels(id);
      const parcels = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: GET_PARCELS_SUCCESS,
        payload: parcels.data,
      });
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);
      dispatch({
        type: GET_PARCELS_FAILURE,
        payload: error,
      });
    }
  };
};
