import axios from "axios";
import Bluebird from "bluebird";
import api from "../api/index";

const GET_ALL_SHIPPINGS_REQUEST = "TYP/GET_ALL_SHIPPINGS_REQUEST";
const GET_ALL_SHIPPINGS_SUCCESS = "TYP/GET_ALL_SHIPPINGS_SUCCESS";
const GET_ALL_SHIPPINGS_LOADING_SUCCESS =
  "TYP/GET_ALL_SHIPPINGS_LOADING_SUCCESS";
const GET_ALL_SHIPPINGS_FAILURE = "TYP/GET_ALL_SHIPPINGS_FAILURE";

const GET_SHIPPINGS_BY_FILTERS_REQUEST = "TYP/GET_SHIPPINGS_BY_FILTERS_REQUEST";
const GET_SHIPPINGS_BY_FILTERS_SUCCESS = "TYP/GET_SHIPPINGS_BY_FILTERS_SUCCESS";
const GET_SHIPPINGS_BY_FILTERS_LOADING_SUCCESS =
  "TYP/GET_SHIPPINGS_BY_FILTERS_LOADING_SUCCESS";
const GET_SHIPPINGS_BY_FILTERS_FAILURE = "TYP/GET_SHIPPINGS_BY_FILTERS_FAILURE";

const GET_SHIPPING_BY_ID_REQUEST = "TYP/GET_SHIPPING_BY_ID_REQUEST";
const GET_SHIPPING_BY_ID_SUCCESS = "TYP/GET_SHIPPING_BY_ID_SUCCESS";
const GET_SHIPPING_BY_ID_FAILURE = "TYP/GET_SHIPPING_BY_ID_FAILURE";

const STOP_SHIPPING_REQUEST = "TYP/STOP_SHIPPING_REQUEST";
const STOP_SHIPPING_SUCCESS = "TYP/STOP_SHIPPING_SUCCESS";
const STOP_SHIPPING_FAILURE = "TYP/STOP_SHIPPING_FAILURE";

const CREATE_NEW_SHIPPING_REQUEST = "TYP/CREATE_NEW_SHIPPING_REQUEST";
const CREATE_NEW_SHIPPING_SUCCESS = "TYP/CREATE_NEW_SHIPPING_SUCCESS";
const CREATE_NEW_SHIPPING_FAILURE = "TYP/CREATE_NEW_SHIPPING_FAILURE";

const SET_SHIPPING = "TYP/SET_SHIPPING";
// const RESET_SHIPPING = "TYP/RESET_SHIPPING";

const GET_TRACKING_BY_ID_REQUEST = "TYP/GET_TRACKING_BY_ID_REQUEST";
const GET_TRACKING_BY_ID_SUCCESS = "TYP/GET_TRACKING_BY_ID_SUCCESS";
const GET_TRACKING_BY_ID_FAILURE = "TYP/GET_TRACKING_BY_ID_FAILURE";

const GET_POD_PDF_REQUEST = "TYP/GET_POD_PDF_REQUEST";
const GET_POD_PDF_SUCCESS = "TYP/GET_POD_PDF_SUCCESS";
const GET_POD_PDF_FAILURE = "TYP/GET_POD_PDF_FAILURE";

const GET_LABEL_REQUEST = "TYP/GET_LABEL_REQUEST";
const GET_LABEL_SUCCESS = "TYP/GET_LABEL_SUCCESS";
const GET_LABEL_FAILURE = "TYP/GET_LABEL_FAILURE";

const GET_CSV_REQUEST = "TYP/GET_CSV_REQUEST";
const GET_CSV_SUCCESS = "TYP/GET_CSV_SUCCESS";
const GET_CSV_FAILURE = "TYP/GET_CSV_FAILURE";

// const UPDATE_SHIPPING_REQUEST = 'TYP/UPDATE_SHIPPING_REQUEST'
// const UPDATE_SHIPPING_SUCCESS = 'TYP/UPDATE_SHIPPING_SUCCESS'
// const UPDATE_SHIPPING_FAILURE = 'TYP/UPDATE_SHIPPING_FAILURE'

const initialState = {
  loading: false,
  loadingSingle: false,
  loadingEvents: false,
  loadingPDF: false,
  error: false,
  data: { shippings: [], pagination: {} },
  count: 10,
  singleShipping: null,
  singleTracking: {},
  label: "",
  podPdf: "",
  csv: null,
  loadingCSV: false,
  shippingStopped: null,
};

export default function reducerRealEstates(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SHIPPINGS_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_ALL_SHIPPINGS_LOADING_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loading: false,
        loadingEvents: true,
        data: action.payload,
        count: action.payload.count,
      };
    case GET_ALL_SHIPPINGS_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loading: false,
        loadingEvents: false,
        data: action.payload,
      };
    case GET_ALL_SHIPPINGS_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };
    case GET_SHIPPINGS_BY_FILTERS_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_SHIPPINGS_BY_FILTERS_LOADING_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loading: false,
        loadingEvents: true,
        data: action.payload,
      };
    case GET_SHIPPINGS_BY_FILTERS_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loading: false,
        loadingEvents: false,
        data: action.payload,
      };
    case GET_SHIPPINGS_BY_FILTERS_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };

    case GET_SHIPPING_BY_ID_REQUEST:
      return { ...state, loadingSingle: true, error: false };
    case GET_SHIPPING_BY_ID_SUCCESS:
      return {
        ...state,
        loadingSingle: false,
        singleShipping: action.payload,
      };
    case GET_SHIPPING_BY_ID_FAILURE:
      return {
        ...state,
        loadingSingle: false,
        singleShipping: {},
        error: action.payload,
      };
    case GET_TRACKING_BY_ID_REQUEST:
      return { ...state, loadingSingle: true, error: false };
    case GET_TRACKING_BY_ID_SUCCESS:
      return {
        ...state,
        loadingSingle: false,
        singleTracking: action.payload,
        shippingStopped: false,
      };
    case GET_TRACKING_BY_ID_FAILURE:
      return {
        ...state,
        loadingSingle: false,
        singleShipping: {},
        error: action.payload,
      };
    case STOP_SHIPPING_REQUEST:
      return { ...state, loadingStop: true, error: false };
    case STOP_SHIPPING_SUCCESS:
      return {
        ...state,
        loadingStop: false,
        shippingStopped: action.payload,
      };
    case STOP_SHIPPING_FAILURE:
      return {
        ...state,
        loadingStop: false,
        errorStop: action.payload,
      };
    case CREATE_NEW_SHIPPING_REQUEST:
      return { ...state, loadingSingle: true, error: false };
    case CREATE_NEW_SHIPPING_SUCCESS:
      return {
        ...state,
        loadingSingle: false,
        singleTracking: action.payload,
        singleShipping: null,
      };
    case CREATE_NEW_SHIPPING_FAILURE:
      return {
        ...state,
        loadingSingle: false,
        singleShipping: {},
        error: action.payload,
      };

    case GET_LABEL_REQUEST:
      return { ...state, loadingPDF: true, error: false };
    case GET_LABEL_SUCCESS:
      return {
        ...state,
        loadingPDF: false,
        label: action.payload,
      };
    case GET_LABEL_FAILURE:
      return { ...state, loadingPDF: false, label: "", error: action.payload };

    case GET_POD_PDF_REQUEST:
      return { ...state, loadingPDF: true, error: false };
    case GET_POD_PDF_SUCCESS:
      return {
        ...state,
        loadingPDF: false,
        podPdf: action.payload,
      };
    case GET_POD_PDF_FAILURE:
      return { ...state, loadingPDF: false, podPdf: "", error: action.payload };

    case GET_CSV_REQUEST:
      return { ...state, loadingCSV: true, error: false };
    case GET_CSV_SUCCESS:
      return {
        ...state,
        loadingCSV: false,
        csv: action.payload,
      };
    case GET_CSV_FAILURE:
      return { ...state, loadingCSV: false, csv: "", error: action.payload };

    case SET_SHIPPING:
      return {
        ...state,
        singleShipping: action.payload,
      };

    // case UPDATE_SHIPPING_REQUEST:
    //   return { ...state, loading: true, error: false }
    // case UPDATE_SHIPPING_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     singleShipping: action.payload,
    //   }
    // case UPDATE_SHIPPING_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   }

    default:
      return state;
  }
}

export const getAllShippings = (reference) => {
  // console.log('reference', reference)
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_SHIPPINGS_REQUEST,
    });

    try {
      const { url, method, headers, params } = api.getAllShippings(reference);

      let shippings = await axios({
        url,
        method,
        headers,
        params,
      });
      // console.log("shippings", shippings.data);
      dispatch({
        type: GET_ALL_SHIPPINGS_LOADING_SUCCESS,
        payload: shippings.data,
      });
      const shipments = await Bluebird.mapSeries(
        shippings.data.shipments,
        async (shipping) => {
          try {
            const { url, method, headers } = api.getTrackingById(shipping.id);
            const shippingDetails = await axios({
              url,
              method,
              headers,
            });
            return {
              ...shipping,
              lastEvent: (shippingDetails?.data?.events || []).reverse()[0]
                ?.eventName,
              pod: shippingDetails?.data?.pod,
            };
          } catch (err) {
            return {
              ...shipping,
              lastEvent: null,
            };
          }
        }
      );

      dispatch({
        type: GET_ALL_SHIPPINGS_SUCCESS,
        payload: { ...shippings, shipments },
      });
      return shippings;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_ALL_SHIPPINGS_FAILURE,
        payload: error,
      });
    }
  };
};

export const searchAllShippings = (reference) => {
  // console.log('reference', reference)
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_SHIPPINGS_REQUEST,
    });

    try {
      const { url, method, headers, params } = api.getAllShippings(reference);

      let shippings = await axios({
        url,
        method,
        headers,
        params,
      });

      dispatch({
        type: GET_ALL_SHIPPINGS_LOADING_SUCCESS,
        payload: shippings.data,
      });
      return shippings;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_ALL_SHIPPINGS_FAILURE,
        payload: error,
      });
    }
  };
};

export const getShippingById = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_SHIPPING_BY_ID_REQUEST,
    });

    try {
      const { url, method, headers } = api.getShippingById(id);

      // console.log("params", params);

      const shipping = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: GET_SHIPPING_BY_ID_SUCCESS,
        payload: shipping.data,
      });
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_SHIPPING_BY_ID_FAILURE,
        payload: error,
      });
    }
  };
};

export const stopShipping = (id) => {
  return async (dispatch) => {
    dispatch({
      type: STOP_SHIPPING_REQUEST,
    });

    try {
      const { url, method, headers } = api.stopShipping(id);

      const shipping = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: STOP_SHIPPING_SUCCESS,
        payload: shipping.data.code,
      });
      return shipping;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: STOP_SHIPPING_FAILURE,
        payload: error,
      });
    }
  };
};

export const getTrackingById = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_TRACKING_BY_ID_REQUEST,
    });

    try {
      const { url, method, headers } = api.getTrackingById(id);

      // console.log("params", params);

      const shipping = await axios({
        url,
        method,
        headers,
      });

      dispatch({
        type: GET_TRACKING_BY_ID_SUCCESS,
        payload: shipping.data,
      });
      return shipping.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_TRACKING_BY_ID_FAILURE,
        payload: error,
      });
    }
  };
};

export const createShipping = (shippingData) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_NEW_SHIPPING_REQUEST,
    });
    try {
      const { url, method, headers, data } = api.createShipping(shippingData);

      const createdShipping = await axios({
        url,
        method,
        headers,
        data,
      });
      dispatch({
        type: CREATE_NEW_SHIPPING_SUCCESS,
        payload: createdShipping.data,
      });
      return createdShipping.data;
    } catch (error) {
      // console.log(error);
      dispatch({
        type: CREATE_NEW_SHIPPING_FAILURE,
        payload: error,
      });
    }
  };
};

export const getLabel = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_LABEL_REQUEST,
    });
    try {
      const { url, method, headers } = api.getLabel(id);

      const label = await axios({
        url,
        method,
        headers,
      });
      dispatch({
        type: GET_LABEL_SUCCESS,
        payload: label.data,
      });
      return label.data;
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_LABEL_FAILURE,
        payload: error,
      });
    }
  };
};

export const getPodPdf = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_POD_PDF_REQUEST,
    });
    try {
      const { url, method, headers } = api.getPodPdf(id);

      const podPdf = await axios({
        url,
        method,
        headers,
      });
      dispatch({
        type: GET_POD_PDF_SUCCESS,
        payload: podPdf.data,
      });
      return podPdf.data;
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_POD_PDF_FAILURE,
        payload: error,
      });
    }
  };
};

export const getCSV = (filters) => {
  return async (dispatch) => {
    dispatch({
      type: GET_CSV_REQUEST,
    });
    try {
      const { url, method, headers, params } = api.getCSV(filters);

      const csv = await axios({
        url,
        method,
        headers,
        params,
      });
      dispatch({
        type: GET_CSV_SUCCESS,
        payload: csv.data,
      });
      return csv.data;
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_CSV_FAILURE,
        payload: error,
      });
    }
  };
};

export const setShipping = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_SHIPPING,
      payload: data,
    });
  };
};

// export const updateShipping = (id, shippingUpdates) => {
//   return async (dispatch) => {
//     dispatch({
//       type: UPDATE_SHIPPING_REQUEST,
//     })
//     try {
//       const { url, method, headers, data } = api.updateShipping(id, shippingUpdates)

//       const updatedShipping = await axios({
//         url,
//         method,
//         headers,
//         data,
//       })
//       dispatch({
//         type: UPDATE_SHIPPING_SUCCESS,
//         payload: updatedShipping.data,
//       })
//       return 'updated'
//     } catch (error) {
//       // console.log(error);
//       dispatch({
//         type: UPDATE_SHIPPING_FAILURE,
//         payload: error,
//       })
//     }
//   }
// }
