const SET_LANGUAGE = "TYP/SET_LANGUAGE";

const initialState = {
  language: navigator.language.toLowerCase(),
};

export default function reducerLanguage(state = initialState, action) {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };

    default:
      return state;
  }
}

export const setLanguage = (lan) => {
  return async (dispatch) => {
    dispatch({
      type: SET_LANGUAGE,
      payload: lan,
    });
  };
};
