import axios from "axios";
import api from "../api/index";

const GET_CONTRACT_BY_ID_REQUEST = "TYP/GET_CONTRACT_BY_ID_REQUEST";
const GET_CONTRACT_BY_ID_SUCCESS = "TYP/GET_CONTRACT_BY_ID_SUCCESS";
const GET_CONTRACT_BY_ID_FAILURE = "TYP/GET_CONTRACT_BY_ID_FAILURE";
// const GET_SUBCONTRACT_BY_ID_REQUEST = "TYP/GET_SUBCONTRACT_BY_ID_REQUEST";
// const GET_SUBCONTRACT_BY_ID_SUCCESS = "TYP/GET_SUBCONTRACT_BY_ID_SUCCESS";
// const GET_SUBCONTRACT_BY_ID_FAILURE = "TYP/GET_SUBCONTRACT_BY_ID_FAILURE";

const initialState = {
  loading: false,
  error: false,
  contractData: "",
  subcontractData: "",
};

export default function reducerContracts(state = initialState, action) {
  switch (action.type) {
    case GET_CONTRACT_BY_ID_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_CONTRACT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        contractData: action.payload,
      };
    case GET_CONTRACT_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        contractData: "",
        error: action.payload,
      };
    // case GET_SUBCONTRACT_BY_ID_REQUEST:
    //   return { ...state, loading: true, error: false };
    // case GET_SUBCONTRACT_BY_ID_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     subcontractData: action.payload,
    //   };
    // case GET_SUBCONTRACT_BY_ID_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     subcontractData: "",
    //     error: action.payload,
    //   };

    default:
      return state;
  }
}

export const getContractById = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_CONTRACT_BY_ID_REQUEST,
    });
    try {
      const { url, method, headers } = api.getContractById(id);
      const contract = await axios({
        url,
        method,
        headers,
      });
      console.log("get contract by id res", contract.data);
      dispatch({
        type: GET_CONTRACT_BY_ID_SUCCESS,
        payload: contract.data,
      });
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_CONTRACT_BY_ID_FAILURE,
        payload: error,
      });
    }
  };
};

// export const getSubcontractById = (id) => {
//   return async (dispatch) => {
//     dispatch({
//       type: GET_SUBCONTRACT_BY_ID_REQUEST,
//     });

//     try {
//       const { url, method, headers } = api.getSubcontractById(id);

//       const subcontract = await axios({
//         url,
//         method,
//         headers,
//       });

//       dispatch({
//         type: GET_SUBCONTRACT_BY_ID_SUCCESS,
//         payload: subcontract.data,
//       });

//       return subcontract.data;
//     } catch (error) {
//       console.log("error", error);
//       console.log("error", error.response);

//       dispatch({
//         type: GET_SUBCONTRACT_BY_ID_FAILURE,
//         payload: error,
//       });
//     }
//   };
// };
export const getSubcontractById = async (id) => {
  try {
    const { url, method, headers } = api.getSubcontractById(id);
    const subcontract = await axios({
      url,
      method,
      headers,
    });

    return subcontract.data;
  } catch (error) {
    console.log("error", error);
    console.log("error", error.response);

    return null;
  }
};
