import axios from "axios";
import api from "../api/index";

const LOGIN_ACCOUNT_SUCCESS = "TYP/LOGIN_ACCOUNT_SUCCESS";
const LOGIN_ACCOUNT_REQUEST = "TYP/LOGIN_ACCOUNT_REQUEST";
const LOGIN_ACCOUNT_FAILURE = "TYP/LOGIN_ACCOUNT_FAILURE";

const LOGOUT_ACCOUNT = "TYP/LOGOUT_ACCOUNT";

// const GET_USER_REQUEST = "TYP/GET_USER_REQUEST";
// const GET_USER_SUCCESS = "TYP/GET_USER_SUCCESS";
// const GET_USER_FAILURE = "TYP/GET_USER_FAILURE";

const initialState = {
  loading: false,
  error: false,
  data: [],
  singleAccount: "",
};

export default function reducerSubscriptions(state = initialState, action) {
  switch (action.type) {
    case LOGIN_ACCOUNT_REQUEST:
      return { ...state, loading: true, error: false };
    case LOGIN_ACCOUNT_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case LOGIN_ACCOUNT_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };
    case LOGOUT_ACCOUNT:
      return { ...initialState };

    // case GET_USER_REQUEST:
    //   return { ...state, loading: true, error: false };
    // case GET_USER_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     singleAccount: action.payload,
    //   };
    // case GET_USER_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   };
    default:
      return state;
  }
}

export const login = (credentials) => {
  return async (dispatch) => {
    dispatch({
      type: LOGIN_ACCOUNT_REQUEST,
    });

    try {
      if (!credentials) {
        const userLs = localStorage.getItem("typApiKey");
        if (userLs) {
          const account = JSON.parse(userLs);
          dispatch({
            type: LOGIN_ACCOUNT_SUCCESS,
            payload: account.apiKey,
          });
        } else {
          dispatch({
            type: LOGIN_ACCOUNT_FAILURE,
            payload: "no_account_in_local_storage",
          });
        }
      } else {
        const { url, method, headers, data } = api.login(credentials);

        const account = await axios({
          url,
          method,
          headers,
          data,
        });

        if (account) {
          dispatch({
            type: LOGIN_ACCOUNT_SUCCESS,
            payload: account.data,
          });

          localStorage.setItem(
            "typApiKey",
            JSON.stringify(account.data.apiKey)
          );
        } else {
          dispatch({
            type: LOGIN_ACCOUNT_FAILURE,
            payload: "no_account_found",
          });
        }
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "account_not_approved"
      ) {
        dispatch({
          type: LOGIN_ACCOUNT_FAILURE,
          payload: "account_not_approved",
        });
      } else {
        dispatch({
          type: LOGIN_ACCOUNT_FAILURE,
          payload: "error_login",
        });
      }
    }
  };
};

export const logout = () => {
  // return async (dispatch) => {
  //   dispatch({
  //     type: LOGOUT_ACCOUNT,
  //   })
  localStorage.clear();
};

export const getUser = async () => {
  try {
    const { url, method, headers } = api.getUser();

    const user = await axios({
      url,
      method,
      headers,
    });

    return user.data;
  } catch (error) {
    console.log(error);
    console.log("error", error.response);

    return "";
  }
};
