import axios from "axios";
import api from "../api/index";

const GET_SERVICES_REQUEST = "TYP/GET_SERVICES_REQUEST";
const GET_SERVICES_SUCCESS = "TYP/GET_SERVICES_SUCCESS";
const GET_SERVICES_FAILURE = "TYP/GET_SERVICES_FAILURE";

const initialState = {
  loading: false,
  error: false,
  data: [],
};

export default function reducerServices(state = initialState, action) {
  switch (action.type) {
    case GET_SERVICES_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_SERVICES_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };

    default:
      return state;
  }
}

// export const getServices = () => {
//   return async (dispatch) => {
//     dispatch({
//       type: GET_SERVICES_REQUEST,
//     })

//     try {
//       const { url, method, headers } = api.getServices()

//       // console.log("params", params);

//       const services = await axios({
//         url,
//         method,
//         headers,
//       })

//       //   console.log('RESPONSE', shippings.data)

//       dispatch({
//         type: GET_SERVICES_SUCCESS,
//         payload: services.data,
//       })
//     } catch (error) {
//       console.log('error', error)
//       console.log('error', error.response)

//       dispatch({
//         type: GET_SERVICES_FAILURE,
//         payload: error,
//       })
//     }
//   }
// }

export const getServices = async (contractCode) => {
  try {
    const { url, method, headers, params } = api.getServices(contractCode);

    const services = await axios({
      url,
      method,
      headers,
      params,
    });

    return services.data;
  } catch (error) {
    console.log("error", error);
    console.log("error", error.response);

    return [];
  }
};
