import axios from "axios";
import api from "../api/index";

const CREATE_BOOKING_REQUEST = "TYP/CREATE_BOOKING_REQUEST";
const CREATE_BOOKING_SUCCESS = "TYP/CREATE_BOOKING_SUCCESS";
const CREATE_BOOKING_FAILURE = "TYP/CREATE_BOOKING_FAILURE";

const initialState = {
  loading: false,
  error: false,
  data: [],
};

export default function reducerRealEstates(state = initialState, action) {
  switch (action.type) {
    case CREATE_BOOKING_REQUEST:
      return { ...state, loading: true, error: false };
    case CREATE_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case CREATE_BOOKING_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };

    default:
      return state;
  }
}

export const createBooking = (data) => {
  // console.log("create booking data", data);
  return async (dispatch) => {
    dispatch({
      type: CREATE_BOOKING_REQUEST,
    });

    try {
      const { url, method, headers } = api.createBooking(data);

      const booking = await axios({
        url,
        method,
        headers,
        data,
      });

      // console.log("create booking response", booking.data);

      dispatch({
        type: CREATE_BOOKING_SUCCESS,
        payload: "success",
      });
      return booking.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: CREATE_BOOKING_FAILURE,
        payload: error,
      });
    }
  };
};
