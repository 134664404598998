const initialState = {
  sidebarShow: false,
};

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN":
      return {
        sidebarShow: true,
      };
    case "CLOSE":
      return {
        sidebarShow: false,
      };

    default:
      return state;
  }
};

export default sidebarReducer;
